(function () {
	'use strict';
	angular
		.module('filters')
		.factory('manageFilters', [
			'$timeout',
			'$rootScope',
			'seedcodeCalendar',
			'utilities',
			'dataStore',
			'manageSettings',
			'manageResources',
			'calendarIO',
			'manageCalendarActions',
			'filemakerJS',
			manageFilters,
		]);

	function manageFilters(
		$timeout,
		$rootScope,
		seedcodeCalendar,
		utilities,
		dataStore,
		manageSettings,
		manageResources,
		calendarIO,
		manageCalendarActions,
		filemakerJS
	) {
		return {
			isEventShown: isEventShown,
			sortFilters: sortFilters,
			selectFilter: selectFilter,
			textFilter: textFilter,
			findTextInFields: findTextInFields,
			toggleFilterFolder: toggleFilterFolder,
			clearFilters: clearFilters,
			resetFilterState: resetFilterState,
			applyFilterSort: applyFilterSort,
			updateStatus: updateStatus,
			updateResource: updateResource,
			availableFilterFolders: availableFilterFolders,
			createAdvanceFilter: createAdvanceFilter,
			restoreFilters: restoreFilters,
			restoreSessionCollapseState: restoreSessionCollapseState,
			getFilterState: getFilterState,
			getEnabledFilterItems: getEnabledFilterItems,
			applyFilterSearch: applyFilterSearch,
			createFilterEdit: createFilterEdit,
			filterIsEditable: filterIsEditable,
			filterIsDynamic: filterIsDynamic,
			filterIsNoFilterLabel: filterIsNoFilterLabel,
		};

		function isEventShown(event, constrainToView) {
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');

			var fieldMap;

			var shareSources = seedcodeCalendar.get('shareSources');
			var shareSource;

			var textFilters = seedcodeCalendar.get('textFilters');
			var testString = ' ';

			var textMatch = true;
			var preFilterMatch = true;

			var customFields;

			if (event.unscheduled) {
				return false;
			}

			if (
				(view.name === 'agendaWeek' ||
					view.name === 'agendaDay' ||
					view.name === 'agendaDays') &&
				event.isUnavailable &&
				((event.resource.length === 1 &&
					event.resource[0] !== config.noFilterLabel) ||
					event.resource.length > 1)
			) {
				return false;
			}

			if (event.isUnavailable || event.isMeasureOnly) {
				return true;
			}

			//View specific exceptions
			if (constrainToView) {
				const inDateRange =
					Number(event.start) < Number(view.end) &&
					Number(event.end) > Number(view.start);

				if (!inDateRange) {
					return;
				}

				if (view.hasTimePaging) {
					if (event.allDay) {
						return false;
					}

					const startHour = event.start.hour();
					const startMinute = event.start.minute();
					const endHour = event.end.hour();
					const endMinute = event.end.minute();

					if (
						startHour > view.endTime[0] ||
						(startHour === view.endTime[0] &&
							startMinute > view.endTime[1]) ||
						endHour < view.startTime[0] ||
						(endHour === view.startTime[0] &&
							endMinute <= view.startTime[1])
					) {
						return false;
					}
				}
			}

			//if we're a new event, we always show
			if (event.eventStatus && event.eventStatus.firstOpen) {
				return true;
			}

			var preFilters = event.schedule.preFilter;

			if (textFilters || preFilters) {
				//build a compound string we can test once
				testString +=
					event.contactName && event.contactName.length
						? event.contactName.join(' ')
						: '';
				testString +=
					event.projectName && event.projectName.length
						? ' ' + event.projectName.join(' ')
						: '';
				testString += event.title ? ' ' + event.title : '';
				testString += event.description ? ' ' + event.description : '';
				testString += event.location ? ' ' + event.location : '';
				testString += event.status.length
					? ' ' + event.status.join(' ')
					: '';
				testString += event.resource.length
					? ' ' + event.resource.join(' ')
					: '';
				testString += event.parentListName
					? ' ' + event.parentListName
					: '';
				testString += event.schedule.name
					? ' ' + event.schedule.name
					: '';
				if (config.isShare) {
					shareSource = shareSources[event.shareScheduleID]
						? shareSources[event.shareScheduleID]
						: shareSources[event.shareSourceID];
					fieldMap = shareSource.fieldMap
						? shareSource.fieldMap
						: event.schedule.fieldMap;
					customFields = utilities.getValidShareCustomFields(
						shareSource,
						event
					);
				} else {
					fieldMap = event.schedule.fieldMap;
					customFields = event.schedule.customFields;
				}
				if (customFields) {
					for (var fieldId in customFields) {
						testString += event[fieldId]
							? ' ' + event[fieldId]
							: '';
					}
				}
				testString = testString.trim();
			}

			if (textFilters) {
				textMatch = findTextInFields(
					textFilters,
					testString,
					event,
					fieldMap,
					customFields
				);
			}

			if (preFilters) {
				preFilterMatch = findTextInFields(
					preFilters,
					testString,
					event,
					fieldMap,
					customFields
				);
			}

			//Loop statuses
			var statusMatch = getFilterMatch(
				'statuses',
				event.status,
				config.noFilterLabel,
				constrainToView
			);

			//Loop Resources
			var resourceMatch = getFilterMatch(
				'resources',
				event.resource,
				config.noFilterLabel,
				constrainToView
			);

			//Loop Projects
			var projectMatch = getFilterMatch(
				'projects',
				event.projectName,
				config.noFilterLabel,
				constrainToView
			);
			projectMatch = !projectMatch
				? getFilterMatch(
						'projects',
						event.title,
						config.noFilterLabel,
						constrainToView
					)
				: projectMatch;

			//Loop Contacts
			var contactMatch = getFilterMatch(
				'contacts',
				event.contactName,
				config.noFilterLabel,
				constrainToView
			);
			contactMatch = !contactMatch
				? getFilterMatch(
						'contacts',
						event.title,
						config.noFilterLabel,
						constrainToView
					)
				: contactMatch;

			//Return if we should show the event or not
			return (
				statusMatch &&
				resourceMatch &&
				projectMatch &&
				contactMatch &&
				textMatch &&
				preFilterMatch
			);

			function getFilterMatch(
				type,
				eventData,
				noFilterLabel,
				constrainToView
			) {
				var filters;
				var filterMatch = false;
				var noneIsSelected;
				var i;
				var ii;
				var deselectCount = 0;
				var shareFilters;
				var shareFilterActive;
				var shareFilter;
				var shareWasFiltered;

				//possible that eventData is null and we'll return false in that situation
				if (!eventData) {
					return false;
				}

				var breakoutField = config.horizonBreakoutField;

				if (
					constrainToView &&
					type === 'resources' &&
					view.hasResourcePaging
				) {
					filters = manageResources.getViewed();
				} else if (
					constrainToView &&
					type === 'resources' &&
					view.name.indexOf('Horizon') > -1 &&
					breakoutField === 'resource'
				) {
					filters = manageResources.getFiltered(false, 'resources');
				} else if (
					constrainToView &&
					type === 'statuses' &&
					view.name.indexOf('Horizon') > -1 &&
					breakoutField === 'status'
				) {
					filters = manageResources.getFiltered(false, 'statuses');
				} else {
					//Otherwise we are on a view that will not hide events unless explicitely filtered
					constrainToView = false;
					filters = seedcodeCalendar.get(type);
				}

				//If this is a share check to see if no filters are applied. We don't want to show events that aren't part of the filters being displayed
				if (config.isShare) {
					shareFilters = seedcodeCalendar.get('share').filters;
					shareFilter = shareFilters ? shareFilters[type] : null;

					if (shareFilter) {
						for (var property in shareFilter) {
							if (
								shareFilter[property].status &&
								shareFilter[property].status.selected
							) {
								shareWasFiltered = true;
								break;
							}
						}

						//If the share had any filters applied then we will reflect that
						if (shareWasFiltered) {
							for (i = 0; i < filters.length; i++) {
								if (
									!filters[i].status ||
									!filters[i].status.selected
								) {
									deselectCount++;
								}
							}

							shareFilterActive =
								deselectCount === filters.length ? true : false;

							//Reset deselect count so we never polute the routine below
							deselectCount = 0;
						}
					}
				}

				//matching a filter or contact filter to the title returns true as well
				//title can be a string or number, convert if a number
				if (
					(type === 'projects' || type === 'contacts') &&
					(typeof eventData === 'string' ||
						typeof eventData === 'number')
				) {
					if (typeof eventData === 'number') {
						eventData = eventData.toString();
					}
					for (i = 0; i < filters.length; i++) {
						if (
							(filters[i].status &&
								filters[i].status.selected &&
								!filters[i].isFolder) ||
							shareFilterActive
						) {
							if (eventData.indexOf(filters[i].name) > -1) {
								return true;
							}
						}
					}
				}

				for (i = 0; i < filters.length; i++) {
					if (
						(filters[i].status &&
							filters[i].status.selected &&
							!filters[i].isFolder) ||
						shareFilterActive
					) {
						for (ii = 0; ii < eventData.length; ii++) {
							if (eventData[ii] === filters[i].name) {
								return true;
							}
						}
					} else {
						deselectCount++;
						//Loop event data to see if we have a match (data match on unselected filter)
						for (ii = 0; ii < eventData.length; ii++) {
							if (eventData[ii] === filters[i].name) {
								filterMatch = true;
							}
						}
					}
				}
				//Check if no filters are selected
				if (constrainToView) {
					return filterMatch;
					// return filterMatch && deselectCount === filters.length;
				} else {
					return deselectCount === filters.length;
				}
			}
		}

		function concatFilterFields(filterItem, fieldMap) {
			var testString = ' ';
			for (var property in fieldMap) {
				if (
					!filterItem[property] ||
					property === 'sort' ||
					property === 'id' ||
					property === 'folderID'
				) {
					continue;
				}
				if (Array.isArray(filterItem[property])) {
					for (var i = 0; i < filterItem[property].length; i++) {
						if (
							filterItem[property][i] !== null &&
							typeof filterItem[property][i] === 'object'
						) {
							testString += ' ' + filterItem[property][i].name;
						} else {
							testString += ' ' + filterItem[property][i];
						}
					}
				} else if (
					filterItem[property] !== null &&
					typeof filterItem[property] === 'object'
				) {
					// Do something if an object. Skipping for now
				} else if (filterItem[property]) {
					testString += ' ' + filterItem[property];
				}
			}
			return testString;
		}

		function findTextInFields(
			textFilter,
			testString,
			item,
			itemFieldMap,
			customFields
		) {
			var operators = [
				'>=',
				'=>',
				'<=',
				'=<',
				'<>',
				'>',
				'<',
				'≥',
				'≤',
				'≠',
				'=',
				'!=',
				'-',
			];
			var operator;

			var result;
			var textMatch;
			var fieldObject;

			if (!testString) {
				testString = concatFilterFields(item, itemFieldMap);
			}

			textFilter = textFilter.replace(/[\n\r]/g, ' ').toLowerCase();

			result = evaluateParentheses(textFilter, testString);

			textMatch = checkTextFilters(result, testString);
			return textMatch;

			function evaluateParentheses(textFilters, testString) {
				//evaluate innermost expression and sub boolean for expression;
				var openCount = (textFilters.match(/\(/g) || []).length;
				var closeCount = (textFilters.match(/\)/g) || []).length;
				if (openCount && openCount === closeCount) {
					var text = textFilters;
					var openSplit = text.split('(');
					var openExp = openSplit[openSplit.length - 1];
					var closeSplit = openExp.split(')');
					var closeExp = closeSplit[0].trim();
					var result = checkTextFilters(closeExp, testString);
					//put back together with result of parentheses expression
					closeSplit[0] = result.toString();
					var closeJoin = closeSplit.join(')');
					openSplit[openSplit.length - 1] = closeJoin;
					result = openSplit.join('(');
					result = result
						.replace('(true)', '!*true*!')
						.replace('(false)', '!*false!*');
					return evaluateParentheses(result, testString);
				} else {
					return textFilters;
				}
			}

			function checkTextFilters(textFilters, testString) {
				var result = true;
				var textFilter = textFilters;
				var operator;
				//check for spaces / multiple words as there could be operators in play
				var spaceSplit = textFilter.split(' ');
				if (spaceSplit.length > 1) {
					//multiple words, check for operators
					//if the last value could be an operator, but we don't know, then drop it.
					//don't do this if previous word is an operator, as we can assume this will be a literal
					//breaking these out into individual clauses per operator for readbility
					var previousWord = spaceSplit[spaceSplit.length - 2];
					if (
						previousWord &&
						previousWord !== 'or' &&
						previousWord !== 'and'
					) {
						var lastWord = spaceSplit[spaceSplit.length - 1];
						if (
							lastWord === 'a' ||
							lastWord === 'an' ||
							lastWord === 'and' ||
							lastWord === 'and'
						) {
							//make sure we're not proceeded by another operator
							spaceSplit.splice(-1, 1);
							textFilter = spaceSplit.join(' ');
						} else if (lastWord === 'o' || lastWord === 'or') {
							spaceSplit.splice(-1, 1);
							textFilter = spaceSplit.join(' ');
						}
					}
					//now check if there are actual operators
					//check ORs first
					//set to false and any trues will reset and break.
					var orSplit = textFilter.split(' or ');
					var andSplit = textFilter.split(' and ');
					if (orSplit.length > 1) {
						//or operator in effect
						result = orCheck(testString, orSplit);
					} //end OR Tests
					else if (andSplit.length > 1) {
						//Now check un-nested ANDs
						//set to true, any falses will reset and break
						result = andCheck(testString, andSplit);
					} //end AND Test
					else {
						fieldObject = fieldCheck(textFilter);
						if (fieldObject) {
							result = fieldMatch(fieldObject);
						} else {
							operator = isOperator(textFilter, true);
							if (operator) {
								//check against each field
								result = checkAllFields(textFilter, operator);
							} else {
								result = match(testString, textFilter);
							}
						}
					}
				} //end checking multiple word / spaces
				else {
					//no spaces
					//could have field operators with no spaces
					fieldObject = fieldCheck(textFilter);
					if (fieldObject) {
						result = fieldMatch(fieldObject);
					} else {
						operator = isOperator(textFilter, true);
						if (operator) {
							//check against each field
							result = checkAllFields(textFilter, operator);
						} else {
							result = match(testString, textFilter);
						}
					}
				}
				return result;
			}

			function getFieldMap() {
				//clone our field map so we don't mess with the event one.
				var fieldMap = JSON.parse(JSON.stringify(itemFieldMap));
				fieldMap.Source = 'Source';
				fieldMap.Title = 'Title'; // Normalize title as we want to search on the calculated content not the calculation
				if (item.schedule) {
					fieldMap[item.schedule.name] = item.schedule.name;
				}
				return fieldMap;
			}

			function getFieldMapObject() {
				var fieldMap = {};
				var fieldMapOriginal = getFieldMap() || {};
				var additionalFields = customFields ? customFields || {} : {};
				var labelMap = item.schedule
					? item.schedule.labelMap || {}
					: {};
				for (var field in fieldMapOriginal) {
					if (fieldMapOriginal[field] && field !== 'recordID') {
						fieldMap[field] = {};
						fieldMap[field][fieldMapOriginal[field].toLowerCase()] =
							true;
						if (labelMap[field]) {
							fieldMap[field][labelMap[field].toLowerCase()] =
								true;
						} else {
							fieldMap[field][field.toLowerCase()] = true;
						}

						if (additionalFields[field]) {
							fieldMap[field][
								additionalFields[field].name.toLowerCase()
							] = true;
						}
					}
				}
				return fieldMap;
			}

			function omitCheck(value) {
				var fieldMap = getFieldMap();
				var result = true;
				var fieldObject;
				for (var field in fieldMap) {
					fieldObject = {};
					fieldObject[field] = value;
					if (!fieldMatch(fieldObject)) {
						result = false;
					}
				}
				return result;
			}

			function checkAllFields(value, operator) {
				var fieldMap = getFieldMap();
				if (item.schedule) {
					fieldMap[item.schedule.name] = item.schedule.name;
				}
				var fieldObject;
				//handle omit a little differently
				if (operator === '-') {
					return omitCheck(value);
				}
				for (var field in fieldMap) {
					fieldObject = {};
					fieldObject[field] = value;
					if (fieldMatch(fieldObject)) {
						return true;
					}
				}
				return false;
			}

			function fieldCheck(clause) {
				//does this clause have a field operator?
				var fieldMap = getFieldMap();
				var fieldMapObject = getFieldMapObject();
				var result = {};
				var thisValue;
				for (var field in fieldMapObject) {
					var thisField = fieldMapObject[field];
					for (var testValue in thisField) {
						thisValue = testValue + ':'.toLowerCase();
						if (
							clause.toLowerCase().trim().indexOf(thisValue) === 0
						) {
							var value = clause
								.substring(thisValue.length)
								.trim();
							result[field] = value;
							return result;
						}
					}
				}

				return false;
			}

			function isOperator(text, begins) {
				for (var i = 0; i < operators.length; i++) {
					if (begins && text.trim().indexOf(operators[i]) === 0) {
						return operators[i];
					} else if (text.trim() === operators[i]) {
						return operators[i];
					}
				}
				return false;
			}

			function fieldMatch(fieldObject) {
				var field = Object.keys(fieldObject)[0];
				var value = fieldObject[field];
				var i;
				var newText;
				if (item.schedule) {
					//check for sources
					if (field.toLowerCase() === 'source') {
						return match(item.schedule.name, value, true);
					}
					if (
						field.toLowerCase() === item.schedule.name.toLowerCase()
					) {
						return !value || match(testString, value, true);
					}
				}
				//treat colon only as wildcard:
				if (!value || isOperator(value)) {
					return true;
				}
				var text = item[field];
				if (text && typeof text === 'object') {
					// test all values in array
					if (isOperator(value, true) === '-') {
						//treat omit a little differently
						var result = true;
						for (i = 0; i < text.length; i++) {
							newText = text[i] === 'none' ? '' : text[i];
							if (!match(newText, value, true)) {
								result = false;
							}
						}
						return result;
					} else {
						for (i = 0; i < text.length; i++) {
							newText = text[i] === 'none' ? '' : text[i];
							if (match(newText, value, true)) {
								return true;
							}
						}
					}
					return false;
				}
				if (text && value === '*') {
					//if criteria is just a wildcard, that's always true
					return true;
				}
				return match(text, value, true);
			}

			function match(text, value, isFieldCheck) {
				if (value === '!*true*!') {
					return true;
				} else if (value === '!*false*!') {
					return false;
				}
				var operator = false;
				for (var i = 0; i < operators.length; i++) {
					if (value.indexOf(operators[i]) === 0) {
						operator = operators[i];
						break;
					}
				}
				if (!operator) {
					return wordMatch(text, value);
				} else {
					value = value.substring(operator.length);
				}
				value = value.trim();
				if (typeof text === 'string') {
					text = text.toLowerCase();
				}
				if (operator && !value) {
					return true;
				}
				//if our criteria is a number value, then we check against each field unless one specified
				if (parseFloat(value) == value) {
					if (isFieldCheck) {
						return operatorMatch(text, parseFloat(value), operator);
					}
					var fieldMap = itemFieldMap;
					for (var field in fieldMap) {
						if (
							typeof item[field] === 'number' &&
							operatorMatch(
								parseFloat(item[field]),
								parseFloat(value),
								operator
							)
						) {
							return true;
						}
					}
					return false;
				} else {
					return operatorMatch(text, value, operator);
				}
			}

			function operatorMatch(text, value, operator) {
				if (operator === '>') {
					return text > value;
				} else if (operator === '<') {
					return text < value;
				} else if (
					operator === '≥' ||
					operator === '>=' ||
					operator === '=>'
				) {
					return text >= value;
				} else if (
					operator === '≤' ||
					operator === '<=' ||
					operator === '=<'
				) {
					return text <= value;
				} else if (operator === '=') {
					return text === value;
				} else if (
					operator === '!=' ||
					operator === '<>' ||
					operator === '≠'
				) {
					return text !== value;
				} else if (operator === '-') {
					return !wordMatch(text, value);
				}
			}

			function numberMatch(criteria) {
				var fieldMap = itemFieldMap;
				for (var field in fieldMap) {
					if (
						item[field] &&
						typeof item[field] === 'number' &&
						item[field] === criteria
					) {
						return true;
					}
				}
				return false;
			}

			function wordMatch(text, criteria) {
				//if criteria is a number, then use the numberMatch
				if (!text && text !== 0) {
					return false;
				}
				if (parseFloat(criteria) == criteria) {
					if (typeof text === 'number') {
						return parseFloat(criteria) === parseFloat(text);
					} else if (numberMatch(parseFloat(criteria))) {
						return true;
					}
				}

				//if our criteria is string, but our text is a number, than treat as text.
				if (typeof criteria !== 'string') {
					criteria = criteria.toString();
				}
				if (typeof text !== 'string') {
					text = text.toString();
				}
				text = text.toLowerCase();
				if (!text) {
					return false;
				}

				//look for wild cards first.
				if (criteria === '*') {
					return true;
				} else if (criteria.indexOf('*') !== -1) {
					criteria = criteria.replace(/\*{2,}/g, '*');
					var wildCardSplit = criteria.trim().split('*');
					if (wildCardSplit.length === 2 && !wildCardSplit[0]) {
						//just one clause so we can do index of
						return text.indexOf(wildCardSplit[1]) !== -1;
					} else if (
						wildCardSplit.length === 2 &&
						!wildCardSplit[1]
					) {
						//just one clause so we can do index of
						return text.indexOf(wildCardSplit[0]) !== -1;
					}
					//if we have multiple clauses then we'll go to reg ex
					var reString = criteria.split('*').join('.*');
					var rgx = new RegExp(reString, 'i');
					var result;
					if (typeof text === 'number') {
						text = text.toString();
					}
					result = text.match(rgx);
					if (result && result.length > 0) {
						return true;
					}
					return false;
				} //end wildcard logic
				if (text === undefined) {
					return false;
				}

				//pad with space or close tag, etc. to pick up 'begins with' type search
				return (
					text.indexOf(criteria) === 0 ||
					text.indexOf(' ' + criteria) > 0 ||
					text.indexOf('>' + criteria) > 0 ||
					text.indexOf('\n' + criteria) > 0 ||
					text.indexOf(',' + criteria) > 0
				);
			}

			function andCheck(text, criteriaArray) {
				//helper function for text filters
				var result = true;
				var operator;
				for (var i = 0; i < criteriaArray.length; i++) {
					var fieldObject = fieldCheck(criteriaArray[i].trim());
					if (fieldObject) {
						if (!fieldMatch(fieldObject)) {
							result = false;
							break;
						}
					} else {
						operator = isOperator(criteriaArray[i], true);
						if (operator) {
							if (
								!checkAllFields(
									criteriaArray[i].trim(),
									operator
								)
							) {
								result = false;
								break;
							}
						} else if (!match(text, criteriaArray[i].trim())) {
							result = false;
							break;
						}
					}
				}
				return result;
			}

			function orCheck(text, criteriaArray) {
				var operator;
				//helper function for text filters
				for (var i = 0; i < criteriaArray.length; i++) {
					//is this clause quoted?
					if (
						criteriaArray[i].toLowerCase().substr(0, 3) !== 'and '
					) {
						//check for nested AND clause within the parent OR clause and honor. Disregard if begins with and
						var subSplit = criteriaArray[i].split(' and ');
						if (subSplit.length > 1) {
							var andOK = andCheck(text, subSplit);
							if (andOK) {
								//this AND clause is OK so our parent OR clause is OK
								return true;
							}
						} else {
							//test this whole clause, and if it's OK,
							//then the whole OR clause is OK and we're done
							var fieldObject = fieldCheck(criteriaArray[i]);
							if (fieldObject) {
								if (fieldMatch(fieldObject)) {
									return true;
								}
							} else {
								operator = isOperator(criteriaArray[i], true);
								if (operator) {
									if (
										checkAllFields(
											criteriaArray[i].trim(),
											operator
										)
									) {
										return true;
									}
								} else if (
									match(text, criteriaArray[i].trim())
								) {
									return true;
								}
							}
						}
					}
				} // end loop
				return false;
			}
		}

		function runFilterActions(filterItem, filterType, isLast) {
			var calendarActions = seedcodeCalendar.get('calendarActions');

			var actionData = {
				item: filterItem,
				filterType: filterType,
				isLast: isLast,
			};

			//Run on filter calendar actions
			if (calendarActions && calendarActions.afterFilterSelection) {
				//Run load actions
				manageCalendarActions.runCalendarActions(
					calendarActions.afterFilterSelection,
					null,
					actionData
				);
			}
		}

		function sortFilters(filterItems) {
			return manageSettings.filterFieldSort(filterItems);
		}

		function updateSchedules() {
			var returned = 0;
			//reload schedules that include filters in their queries
			var config = seedcodeCalendar.get('config');
			var element = seedcodeCalendar.get('element');
			var scheduleCount = 0;
			var schedules = seedcodeCalendar.get('schedules');

			if (!config.preventFilterUpdateSchedules) {
				for (var i = 0; i < schedules.length; i++) {
					if (
						(schedules[i].sourceTypeID === 4 ||
							schedules[i].sourceTypeID === 10 ||
							schedules[i].sourceTypeID === 8) &&
						schedules[i].status &&
						schedules[i].status.selected &&
						(schedules[i].includeFilters ||
							schedules[i].requireFilters)
					) {
						scheduleCount++;
						element.fullCalendar(
							'refetchEventSource',
							schedules[i].source,
							schedules[i].id
						);
					}
				}
			} else {
				config.preventFilterUpdateSchedules = null;
			}
			if (scheduleCount === 0) {
				$rootScope.$broadcast('updateFilters', true);
			} else {
				$rootScope.$on('eventsRendered', function () {
					$rootScope.$broadcast('updateFilters', true);
				});
			}
		}

		function selectedFilterCount() {
			var selectedCount = 0;
			var statuses = seedcodeCalendar.get('statuses');
			var resources = seedcodeCalendar.get('resources');
			var all = statuses.concat(resources);
			for (var i = 0; i < all.length; i++) {
				if (all[i].status && all[i].status.selected) {
					selectedCount++;
				}
			}
			return selectedCount;
		}

		function requiredFilterCount() {
			var scheduleCount = 0;
			var schedules = seedcodeCalendar.get('schedules');
			for (var i = 0; i < schedules.length; i++) {
				if (
					(schedules[i].sourceTypeID === 4 ||
						schedules[i].sourceTypeID === 10 ||
						schedules[i].sourceTypeID === 8) &&
					schedules[i].status &&
					schedules[i].status.selected &&
					schedules[i].includeFilters
				) {
					scheduleCount++;
				}
			}
			return scheduleCount;
		}

		function selectFilter(filterItem, type) {
			var textFilters = seedcodeCalendar.get('textFilters');
			var selectedCount = selectedFilterCount();
			var state = filterItem.status.selected ? true : false;
			var scheduleCount = requiredFilterCount();

			applyFilterSelection(filterItem, type, false);

			var currentCount = selectedFilterCount();

			//reload schedules that include filters in their queries
			if (
				(type === 'status' || type === 'resource') &&
				selectedCount === 0 &&
				(!textFilters || textFilters.length === 0)
			) {
				//this is the first filter turned on, so requery the appropriate schedules
				updateSchedules();
			} else if (
				selectedCount > 0 &&
				currentCount === 0 &&
				(!textFilters || textFilters.length === 0)
			) {
				//this is the last filter being turned off, so requery the appropriate schedules

				updateSchedules();
			} else if (scheduleCount > 0) {
				updateSchedules();
			} else {
				$rootScope.$broadcast('updateFilters', false);
			}
		}

		function applyFilterSelection(filterItem, type, multiSelection) {
			var filterList;
			var filterType;
			var changedFilters = seedcodeCalendar.get('changedFilters');
			var config = seedcodeCalendar.get('config');
			var folderPosition;
			var folderIsSelected;
			var folderHasSelectedFilter;
			var folderHasDeselectedFilter;

			if (type === 'status') {
				filterType = 'statuses';
			} else if (type === 'resource') {
				filterType = 'resources';
			} else if (type === 'project') {
				filterType = 'projects';
			} else if (type === 'contact') {
				filterType = 'contacts';
			}

			// Only select shown filters when clicking folders
			if (
				config[`${type}ListFilter`] &&
				config[`${filterType}Filtered`]?.length
			) {
				filterList = config[`${filterType}Filtered`];
			} else {
				filterList = seedcodeCalendar.get(filterType);
			}

			if (filterItem.status.selected) {
				filterItem.status.selected = false;
			} else {
				//Add schedule to calendar's view
				filterItem.status.selected = true;
			}

			// Add changed filter item to changed filter list
			changedFilters.push(filterItem);

			// If a folder is selected match all children of the folder to the selection state
			filterList.forEach(function (element, index, array) {
				// If the id matches the folder id that means a folder was selected
				// so match the state to anything in the folder
				if (
					filterList[index].folderID === filterItem.id &&
					filterItem.id
				) {
					filterList[index].status.selected =
						filterItem.status.selected;
					if (!filterList[index].isFolder) {
						changedFilters.push(filterList[index]);
					}
				}
				// Check if a child in a folder was selected. If no others are then deselect the folder
				else if (
					filterItem.folderID &&
					filterList[index].folderID === filterItem.folderID
				) {
					// Check selection state
					if (filterItem.status.selected) {
						if (
							!filterList[index].status.selected &&
							!filterList[index].isFolder
						) {
							// There is a child selected so nothing to do
							folderHasDeselectedFilter = true;
						} else if (
							filterItem.folderID &&
							filterList[index].folderID ===
								filterItem.folderID &&
							!filterList[index].status.selected &&
							filterList[index].isFolder
						) {
							folderIsSelected = false;
							folderPosition = index;
						}
					}
					// Check deselection state
					else if (!filterItem.status.selected) {
						if (
							filterList[index].status.selected &&
							!filterList[index].isFolder
						) {
							// There is a child selected so nothing to do
							folderHasSelectedFilter = true;
						} else if (
							filterItem.folderID &&
							filterList[index].folderID ===
								filterItem.folderID &&
							filterList[index].status.selected &&
							filterList[index].isFolder
						) {
							folderIsSelected = true;
							folderPosition = index;
						}
					}
				}
			});

			// Select folder if all children are selected
			if (
				filterItem.status.selected &&
				folderIsSelected !== undefined &&
				!folderIsSelected &&
				!folderHasDeselectedFilter
			) {
				filterList[folderPosition].status.selected = true;
			}
			// Deselect folder if no children are selected
			else if (
				!filterItem.status.selected &&
				folderIsSelected &&
				!folderHasSelectedFilter
			) {
				filterList[folderPosition].status.selected = false;
			}

			//run the actions now so we can determine which is the last action
			if (changedFilters.length === 1) {
				runFilterActions(filterItem, filterType, true);
			} else {
				for (var i = 0; i < changedFilters.length; i++) {
					var isLast = false;
					if (changedFilters.length - i === 1) {
						var isLast = true;
					}
					if (!changedFilters[i].isFolder) {
						runFilterActions(changedFilters[i], filterType, isLast);
					}
				}
			}

			if (!multiSelection) {
				//Update our filter saved state
				updateFilterSavedState(filterList, type);

				$timeout(function () {
					manageResources.reset(false, false, true);
				}, 0);
			}
		}

		function textFilter(keyword, schedule) {
			var current = seedcodeCalendar.get('textFilters');
			var changedFilters = seedcodeCalendar.get('changedFilters');
			var selectedCount = selectedFilterCount();

			//Set rather than init so we don't broadcast change causing loops in the view / controller
			seedcodeCalendar.set('textFilters', null, keyword);
			dataStore.saveState('textFilters', keyword);

			if (
				selectedCount === 0 &&
				(!current || current.length === 0) &&
				keyword &&
				keyword.length > 0
			) {
				updateSchedules();
			} else if (
				selectedCount === 0 &&
				current &&
				current.length > 0 &&
				(!keyword || keyword.length === 0)
			) {
				updateSchedules();
			} else {
				$rootScope.$broadcast('updateFilters', true);
			}

			changedFilters.push('text');

			$timeout(function () {
				manageResources.reset(null, true);
			}, 0);
		}

		function updateFilterSavedState(filterList, type) {
			var output = [];

			if (type === 'textFilters') {
				dataStore.saveState(type, filterList);
				return;
			}

			for (var i = 0; i < filterList.length; i++) {
				if (filterList[i].status && filterList[i].status.selected) {
					output.push(filterList[i].name);
				}
			}

			dataStore.saveState(type + 'Filters', JSON.stringify(output));
		}

		function toggleFilterFolder(filterItem, type) {
			var filterList;
			var expanded;
			if (type === 'status') {
				filterList = seedcodeCalendar.get('statuses');
			} else if (type === 'resource') {
				filterList = seedcodeCalendar.get('resources');
			} else if (type === 'project') {
				filterList = seedcodeCalendar.get('projects');
			} else if (type === 'contact') {
				filterList = seedcodeCalendar.get('contacts');
			}

			expanded = !filterItem.status.folderExpanded;

			for (var i = 0; i < filterList.length; i++) {
				if (filterList[i].folderID === filterItem.folderID) {
					filterList[i].status.folderExpanded = expanded;
				}
			}

			//Update our filter saved state
			updateFilterFolderSavedState(filterList, type);
		}

		function updateFilterFolderSavedState(filterList, type) {
			var output = [];

			for (var i = 0; i < filterList.length; i++) {
				if (
					filterList[i].status &&
					filterList[i].isFolder &&
					filterList[i].status.folderExpanded
				) {
					output.push(filterList[i].id);
				}
			}
			dataStore.saveState(
				type + 'FolderExpanded',
				JSON.stringify(output)
			);
		}

		function clearFilters(refetch) {
			update();
			updateSchedules();

			function update() {
				var statuses = seedcodeCalendar.get('statuses');
				var resources = seedcodeCalendar.get('resources');
				var projects = seedcodeCalendar.get('projects');
				var contacts = seedcodeCalendar.get('contacts');

				seedcodeCalendar.init('textFilters', null, '');
				dataStore.saveState('textFilters', '');

				resetSelected(projects, 'projects');
				resetSelected(contacts, 'contacts');

				resetSelected(statuses, 'statuses');
				//update saved state
				updateFilterSavedState(statuses, 'status');

				applyFilterSearch('', 'resource', false, true, null);

				resetSelected(resources, 'resources');

				manageResources.reset();

				//update saved state
				updateFilterSavedState(resources, 'resource');
			}
		}

		function resetSelected(filterList, type) {
			var changedFilters = seedcodeCalendar.get('changedFilters');
			var runActions = [];
			//Find selected filters and reset them
			for (var i = 0; i < filterList.length; i++) {
				if (filterList[i].status.selected) {
					filterList[i].status.selected = false;
					runActions.push(filterList[i]);
					// Add changed filter item to changed filter list
					changedFilters.push(filterList[i]);
				}
			}
			//run our actions now that we know which is the last filter selected
			for (var a = 0; a < runActions.length; a++) {
				var isLast = false;
				if (runActions.length - a === 1) {
					isLast = true;
				}
				runFilterActions(runActions[a], type, isLast);
			}
		}

		function resetFilterState() {
			var textFilters = seedcodeCalendar.get('textFilters');
			var statuses = seedcodeCalendar.get('statuses');
			var resources = seedcodeCalendar.get('resources');
			var projects = seedcodeCalendar.get('projects');
			var contacts = seedcodeCalendar.get('contacts');
			var changedFilters = seedcodeCalendar.get('changedFilters');

			if (textFilters) {
				// Add changed filter item to changed filter list
				changedFilters.push(textFilters);
			}

			textFilters = '';
			seedcodeCalendar.init('textFilters', null, textFilters);
			updateFilterSavedState(textFilters, 'textFilters');

			resetSelected(projects);
			updateFilterSavedState(statuses, 'project');

			resetSelected(contacts);
			updateFilterSavedState(statuses, 'contact');

			resetSelected(statuses);
			updateFilterSavedState(statuses, 'status');

			resetSelected(resources);
			updateFilterSavedState(resources, 'resource');

			function resetSelected(filterList) {
				//Find selected filters and reset them
				for (var i = 0; i < filterList.length; i++) {
					filterList[i].status.selected = false;
					filterList[i].status.collapsed = false;
					filterList[i].status.folderExpanded = false;
				}
			}
		}

		function applyFilterSort(
			type,
			newPosition,
			originalPosition,
			items,
			operation,
			numberAll,
			fromEdit
		) {
			var sortValue;
			var itemResult = {};
			var config = seedcodeCalendar.get('config');
			var item = items[originalPosition];

			var folderItemCount = 0;

			var filterType;

			if (item.isFolder) {
				//Adjust for folder contents
				for (var i = originalPosition; i < items.length; i++) {
					if (item.folderID === items[i].folderID) {
						folderItemCount++;
					} else {
						break;
					}
				}
			} else {
				folderItemCount = 1;
			}

			if (!numberAll) {
				// Adjust postiion in array
				for (var i = 0; i < folderItemCount; i++) {
					// if new position doesn't exist we assume deletion. Don't remove from list as we need to flag it for delete. Instead put it at the end.
					if (!newPosition) {
						items.splice(
							items.length - 1,
							0,
							items.splice(originalPosition, 1)[0]
						);
					} else if (newPosition > originalPosition) {
						items.splice(
							newPosition,
							0,
							items.splice(originalPosition, 1)[0]
						);
					} else {
						items.splice(
							newPosition + i,
							0,
							items.splice(originalPosition + i, 1)[0]
						);
					}
				}
			}

			var rangeStart = numberAll
				? originalPosition
				: originalPosition > newPosition
					? newPosition
					: originalPosition;
			var rangeEnd = numberAll
				? newPosition
				: originalPosition < newPosition
					? originalPosition
					: newPosition;

			// Adjust sort value and build firebase update object
			for (var i = 0; i < items.length; i++) {
				sortValue = i;
				// if (true) {

				if (!newPosition && item.id === items[i].id && !numberAll) {
					// This is assumed to be a deleted item so don't do anything here
				} else if (numberAll) {
					items[i].sort = sortValue;
					itemResult[items[i].id] = {
						sort: sortValue,
					};
				} else if (
					items[i].name !== config.noFilterLabel &&
					(!items[i].sort ||
						items[i].sort !== sortValue ||
						(i >= rangeStart && i <= rangeEnd))
				) {
					items[i].sort = sortValue;
					itemResult[items[i].id] = {
						sort: sortValue,
					};
				}
			}

			if (!fromEdit) {
				// The code below runs already when updating a filter item
				// So this only needs to run when this is initiated from a drag event
				for (var property in itemResult) {
					manageSettings.updateFilterData(
						type,
						property,
						itemResult[property]
					);
				}
				applyFilterChanges(type, 'edit', items, null);
			}
		}

		function updateFilterSort(type, item, items, operation) {
			var sortValue = 0;
			var originalPosition;
			var newFolderPosition;
			var newPosition;
			var hasSort;
			var numberAll;

			for (var i = 0; i < items.length; i++) {
				// Check to see if there is currently a custom sort order
				if (items[i].sort) {
					hasSort = true;
				}

				if (operation === 'create') {
					if (item.folderID) {
						if (
							items[i].isFolder &&
							items[i].folderID === item.folderID
						) {
							// The position of the folder itself
							newFolderPosition = i;
						}
						if (
							newFolderPosition &&
							!sortValue &&
							(items[i].folderID !== item.folderID ||
								(!sortValue && i === items.length - 1))
						) {
							sortValue =
								items[i].folderID !== item.folderID
									? i - 1
									: i + 1;
						}
					} else {
						if (items[i].sort > sortValue) {
							sortValue = items[i].sort;
						}
					}
				} else if (operation === 'edit') {
					// Check to see if the folder has changed and note the position of the item
					if (
						items[i].id === item.id &&
						items[i].folderID !== item.folderID
					) {
						originalPosition = i;
					}
					if (
						items[i].isFolder &&
						items[i].folderID === item.folderID
					) {
						// The position of the folder itself
						newFolderPosition = i;
					}
					if (
						newFolderPosition &&
						!newPosition &&
						(items[i].folderID !== item.folderID ||
							(!newPosition && i === items.length - 1))
					) {
						newPosition =
							originalPosition &&
							originalPosition < i &&
							i < items.length - 1
								? i - 1
								: i;
						// sortValue = i;
					}
				} else if (operation === 'delete') {
					if (items[i].id === item.id) {
						originalPosition = i;
						break;
					}
				}
			}

			// If there is no custom sort currently then we want to keep the default sort
			if (!hasSort) {
				return;
			}

			if (operation === 'create') {
				if (!item.folderID) {
					sortValue++;
				}
				item.sort = sortValue;

				// Check for existing sort
				for (var i = 0; i < items.length; i++) {
					if (sortValue && items[i].sort === sortValue) {
						numberAll = true;
						sortValue = sortValue - 0.1;
					}
				}

				if (numberAll) {
					applyFilterSort(
						type,
						items.length,
						0,
						items,
						operation,
						numberAll,
						true
					);
				}
			} else {
				if (!originalPosition) {
					return;
				}
				if (operation === 'edit' && !item.folderID) {
					newPosition = items.length - 1;
				}
				item.sort = newPosition;
				applyFilterSort(
					type,
					newPosition,
					originalPosition,
					items,
					operation,
					null,
					true
				);
			}
		}

		function updateStatus(operation, statusObj, fieldMap, successCallback) {
			var statuses = seedcodeCalendar.get('statuses');
			var statusesBackup = statuses.slice(0);
			var async = true;
			var name = statusObj.name;

			updateFilterSort('statuses', statusObj, statuses, operation);

			var statusChanges = updateFilter(
				statuses,
				operation,
				statusObj,
				fieldMap,
				'status'
			);

			successCallback(statusChanges);

			if (async) {
				applyFilterChanges(
					'statuses',
					operation,
					statuses,
					statusChanges
				);
			}

			manageSettings.updateStatus(
				statusChanges,
				name,
				operation,
				processStatuses
			);

			function processStatuses(result) {
				//Report an error and revert data
				if (!result || result.error) {
					utilities.showMessage(
						'There was a problem saving your data and it will be reverted: ' +
							result.message,
						0,
						8000,
						'error'
					);
					applyFilterChanges(
						'statuses',
						operation,
						statusesBackup,
						statusChanges
					);
				} else if (!async) {
					applyFilterChanges(
						'statuses',
						operation,
						result,
						statusChanges
					);
				}
				//if we're in FileMakerJS update our values
				if (
					utilities.getDBKPlatform() &&
					(utilities.getDBKPlatform() === 'dbkfmjs' ||
						utilities.getDBKPlatform() === 'dbkfmwd')
				) {
					filemakerJS.loadFileMakerJSConfig();
				}
			}
		}

		function updateResource(
			operation,
			resourceObj,
			fieldMap,
			successCallback
		) {
			var resources = seedcodeCalendar.get('resources');
			var resourcesBackup = resources.slice(0);
			var async = true;

			updateFilterSort('resources', resourceObj, resources, operation);

			var resourceChanges = updateFilter(
				resources,
				operation,
				resourceObj,
				fieldMap,
				'resource'
			);

			successCallback(resourceChanges);

			if (async) {
				applyFilterChanges(
					'resources',
					operation,
					resources,
					resourceChanges
				);
			}

			manageSettings.updateResource(
				resourceChanges,
				operation,
				processResources
			);

			function processResources(result) {
				//Report an error and revert data
				if (!result || result.error) {
					utilities.showMessage(
						'There was a problem saving your data and it will be reverted: ' +
							result.message,
						0,
						8000,
						'error'
					);
					applyFilterChanges(
						'resources',
						operation,
						resourcesBackup,
						resourceChanges
					);
				} else if (!async) {
					applyFilterChanges(
						'resources',
						operation,
						result,
						resourceChanges
					);
				}
				//if we're in FileMakerJS update our values
				if (
					utilities.getDBKPlatform() &&
					(utilities.getDBKPlatform() === 'dbkfmjs' ||
						utilities.getDBKPlatform() === 'dbkfmwd')
				) {
					filemakerJS.loadFileMakerJSConfig();
				}
			}
		}

		function applyFilterChanges(
			type,
			operation,
			items,
			changes,
			preventBroadcast
		) {
			var events;
			var calendarElement;
			var config = seedcodeCalendar.get('config');
			var filteredItems;
			var itemResult;

			if (operation === 'edit' || (operation === 'create' && changes)) {
				for (var property in changes) {
					//Assign folder name if filter is in a folder
					getFolderName(items, changes[property]);
					applyFolderName(items, changes[property]);
				}
			}

			itemResult = manageSettings.mutateFilterFieldList(
				items,
				null,
				true
			);

			// Get filtered items if the exist.
			// If any exist we need to update filtered items to match changes
			filteredItems = config[type + 'Filtered'];

			if (
				filteredItems &&
				filteredItems.length &&
				config.resourceListFilter
			) {
				config[type + 'Filtered'] = getFilteredItems(
					config.resourceListFilter,
					type,
					itemResult
				);
			} else {
				config[type + 'Filtered'] = null;
			}

			// Init filter values stored in seedcodeCalendar
			seedcodeCalendar.init(type, itemResult, preventBroadcast);

			if (type === 'statuses') {
				calendarElement = seedcodeCalendar.get('element');
				//Loop through events and update the color in case the color changed when updating the status
				events = calendarElement.fullCalendar('clientEvents');
				for (var i = 0; i < events.length; i++) {
					calendarIO.assignEventColor(events[i]);
				}
			}

			manageResources.reset();
		}

		function getFolderName(filterList, filterItem) {
			if (filterItem.folderID) {
				filterList.forEach(function (value, index, array) {
					if (
						value.isFolder &&
						value.folderID === filterItem.folderID
					) {
						filterItem.folderName = value.name;
					}
				});
			}
		}

		function applyFolderName(filterList, filterItem) {
			var expanded;
			//Get the current expanded status of the folder so we can update any children of that folder
			if (filterItem.folderID) {
				for (var i = 0; i < filterList.length; i++) {
					if (
						filterList[i].isFolder &&
						filterList[i].folderID === filterItem.folderID
					) {
						expanded = filterList[i].status.folderExpanded;
					}
				}

				//Apply necessary changes - Folder Name and folder expanded status
				filterList.forEach(function (value, index, array) {
					if (value.folderID === filterItem.folderID) {
						filterList[index].folderName = filterItem.folderName;
						filterList[index].status.folderExpanded = expanded;
					}
				});
			}
		}

		function updateFilter(filterList, operation, editObj, fieldMap, type) {
			var filterChanges = {};
			var filterChangesResult = {};
			var newFilter = false;

			//run our broadcast now since we return in multiple places
			$rootScope.$broadcast('updateFilters', true);
			//operation options: 'create', 'edit', 'delete'
			if (!editObj.name && operation !== 'delete') {
				return;
			}

			//Delete filter
			if (operation === 'delete') {
				filterChangesResult = markFiltersForDelete(filterList, editObj);
				return filterChangesResult;
			}

			//Create new filter
			if (
				(operation === 'edit' && !editObj.id) ||
				operation === 'create'
			) {
				newFilter = true;
				editObj.id = utilities.generateUID();
				editObj.status = {};
				if (editObj.isFolder) {
					editObj.folderID = editObj.id;
					editObj.status.folderExpanded = true;
				}
				editObj.status.selected = false;
				filterList.push(JSON.parse(JSON.stringify(editObj)));
			} else if (!editObj.folderID && editObj.folderName) {
				// If this is an edit and the folder id has been removed make sure to remove the folder name too
				editObj.folderName = null;
			}

			//Get position and edit filter
			for (var i = 0; i < filterList.length; i++) {
				if (filterList[i].id === editObj.id) {
					for (var property in editObj) {
						filterList[i][property] = editObj[property];
					}
					manageSettings.mutateFilterField(filterList[i], true);
					break;
				}
			}

			if (newFilter && editObj.isFolder) {
				//Update filter folder expanded state for new items
				updateFilterFolderSavedState(filterList, type);
			}

			//Apply our changes to an object that we can use in the return data
			for (var property in fieldMap) {
				filterChanges[fieldMap[property]] =
					editObj[fieldMap[property]] === undefined
						? null
						: editObj[fieldMap[property]];
			}

			filterChangesResult[editObj.id] = filterChanges;

			return filterChangesResult;
		}

		function markFiltersForDelete(filterList, filterItem) {
			var result = {};
			var markedForRemoval = [];
			filterList.forEach(function (value, index, array) {
				if (
					(value.folderID &&
						filterItem.isFolder &&
						value.folderID === filterItem.folderID) ||
					(!filterItem.isFolder && value.id === filterItem.id)
				) {
					result[value.id] = null;
					//Mark item for removal
					markedForRemoval.push(index);
				}
			});
			//Remove anything marked for removal from our filterList array. Start from end so we don't remove from wrong index
			markedForRemoval.sort(compareNumbers);
			markedForRemoval.forEach(function (value, index, array) {
				filterList.splice(value, 1);
			});

			function compareNumbers(a, b) {
				return b - a;
			}

			return result;
		}

		function availableFilterFolders(filterList) {
			//Create new array with just data we need
			var result = [];
			for (var i = 0; i < filterList.length; i++) {
				if (filterList[i].isFolder) {
					result.push({
						id: filterList[i].id,
						name: filterList[i].name,
						folderID: filterList[i].folderID,
						folderName: filterList[i].folderName,
					});
				}
			}
			return result;
		}

		function createAdvanceFilter(filters, type) {
			var share = seedcodeCalendar.get('share');

			var stateIdentifier = type + 'List';
			var result = [];
			var obj;

			if (share) {
				filters = getShareFilterItems(
					type,
					'name',
					share.filters,
					function (filterItem) {
						if (filterItem.status && filterItem.status.selected) {
							return true;
						}
					}
				);
			}

			for (var i = 0; i < filters.length; i++) {
				//Check if null is passed and if so we want to clear stored values
				if (filters[i] === 'null') {
					dataStore.clearState(stateIdentifier);
					return [];
				}
				obj = {
					id: i,
					name: filters[i],
					status: {},
				};
				result.push(obj);
			}

			//If this is not a share then let's save the filter item to local storage
			if (!share) {
				if (result.length) {
					dataStore.saveState(
						stateIdentifier,
						JSON.stringify(result)
					);
				} else {
					try {
						result =
							JSON.parse(dataStore.getState(stateIdentifier)) ||
							[];
					} catch (error) {
						result = [];
					}
				}
			}

			return result;
		}

		function getShareFilterItems(
			type,
			returnProperty,
			shareFilters,
			resultFilter
		) {
			var filterItems;
			var output = [];

			if (shareFilters) {
				filterItems = shareFilters[type];
			}

			if (type === 'text') {
				if (filterItems === undefined || filterItems === null) {
					filterItems = '';
				}
				return filterItems;
			}

			if (!filterItems) {
				return [];
			}

			for (var property in filterItems) {
				if (
					!resultFilter ||
					(resultFilter && resultFilter(filterItems[property]))
				) {
					output.push(filterItems[property][returnProperty]);
				}
			}
			return output;
		}

		function restoreFilters(type, defaultItems, preFilter, shareData) {
			//Type can be 'resources', 'statuses', 'contacts', 'projects'
			var share = shareData ? shareData : seedcodeCalendar.get('share');
			var isShare = seedcodeCalendar.get('config').isShare;
			var noFilterLabel = seedcodeCalendar.get('config').noFilterLabel;
			var filterList = seedcodeCalendar.get(type);
			var field;
			var forcePreFilter;
			var preFolderExpandedState;
			var preCollapseState;
			var matchingFilter;
			var unmatchedFilters = false;

			var filterListUpdated = false;

			var savePreFilter;

			if (type === 'statuses') {
				field = 'status';
			} else if (type === 'resources') {
				field = 'resource';
			} else if (type === 'projects') {
				field = 'project';
			} else if (type === 'contacts') {
				field = 'contact';
			} else if (type === 'text') {
				field = 'text';
			}

			if (share) {
				if (isShare && share.allFilters) {
					filterListUpdated = true;
					filterList = [];
				}
				forcePreFilter = true;
				//Get prefilter items so we can enable filter selection state
				preFilter = getShareFilterItems(
					type,
					'name',
					share.filters,
					function (filterItem) {
						filterItem =
							manageSettings.mutateFilterField(filterItem);

						if (filterItem.status && filterItem.status.selected) {
							//Find if matching filter already in filter list
							matchingFilter = false;
							for (var i = 0; i < filterList.length; i++) {
								if (
									filterItem.id === filterList[i].id ||
									filterItem.name === filterList[i].name
								) {
									matchingFilter = true;
									filterList[i].status.selected =
										filterItem.status.selected;
								}
							}
							if (!isShare) {
								unmatchedFilters =
									unmatchedFilters || !matchingFilter;
							} else if (
								!matchingFilter &&
								(share.allFilters ||
									filterItem.name !== noFilterLabel)
							) {
								filterListUpdated = true;
								filterList.push(filterItem);
							}
							return true;
						}

						//For shares with no filters selected, add all from share
						else if (isShare && share.allFilters) {
							filterList.push(filterItem);
							return true;
						}
					}
				);

				// if (!isShare && unmatchedFilters){
				// 	return false;
				// }

				if (filterListUpdated) {
					seedcodeCalendar.init(type, sortFilters(filterList));
				}
				if (type !== 'text') {
					// Store state if not text and this is from a bookmark load
					if (share && !isShare && preFilter) {
						savePreFilter = true;
					}

					//Get collapse state items so we can collapse appropriate item rows
					preFolderExpandedState = getShareFilterItems(
						type,
						'id',
						share.filters,
						function (filterItem) {
							if (
								filterItem.status &&
								filterItem.status.folderExpanded
							) {
								return true;
							}
						}
					);
					//Get collapse state items so we can collapse appropriate item rows
					preCollapseState = getShareFilterItems(
						type,
						'name',
						share.filters,
						function (filterItem) {
							if (
								filterItem.status &&
								filterItem.status.collapsed
							) {
								return true;
							}
						}
					);
				}
			}
			restoreSessionCollapseState(filterList, field, preCollapseState);
			restoreSessionFolderExpandedState(
				filterList,
				field,
				preFolderExpandedState
			);
			restoreSessionFilters(
				filterList,
				type,
				field,
				defaultItems,
				preFilter,
				forcePreFilter,
				share && share.allFilters,
				savePreFilter
			);

			$rootScope.$broadcast('updateFilters', true);

			return true;
		}

		function restoreSessionFilters(
			filterList,
			type,
			field,
			defaultItems,
			preFilter,
			forcePreFilter,
			allFilters,
			savePreFilter
		) {
			//defaultItems should be a comma delimited string of filters
			var config = seedcodeCalendar.get('config');
			var isShare = config.isShare;
			//Load saved filters
			var savedFilters;
			var stateIdentifier;
			var folderHash = {};
			var i;
			var ii;

			stateIdentifier = field + 'Filters';

			if (field === 'text') {
				//retrieve text filters from local storage
				savedFilters = defaultItems
					? defaultItems
					: dataStore.getState('textFilters');

				if (
					(forcePreFilter &&
						preFilter !== 'null' &&
						preFilter !== 'undefined') ||
					((savedFilters === '' ||
						savedFilters === 'null' ||
						savedFilters === 'undefined') &&
						preFilter !== '' &&
						preFilter !== 'null' &&
						preFilter !== 'undefined')
				) {
					savedFilters = preFilter;
				}

				if (savedFilters === 'null' || savedFilters === 'undefined') {
					savedFilters = '';
				}

				// Add any url parameter text filters if we are in a share
				if (config.isShare && defaultItems) {
					savedFilters = defaultItems;
				}

				seedcodeCalendar.init('textFilters', savedFilters);
				dataStore.saveState('textFilters', savedFilters);

				return;
			}

			try {
				savedFilters =
					defaultItems && defaultItems.length
						? defaultItems
						: JSON.parse(dataStore.getState(stateIdentifier));
			} catch (error) {
				savedFilters = [];
			}

			if (
				(savePreFilter ||
					!savedFilters ||
					(savedFilters && savedFilters.length === 0)) &&
				preFilter &&
				preFilter.length
			) {
				savedFilters = preFilter;
			} else if (
				!savedFilters ||
				(savedFilters && savedFilters.length === 0)
			) {
				return;
			}

			if (savedFilters[0] === 'null') {
				dataStore.clearState(stateIdentifier);
				for (i = 0; i < filterList.length; i++) {
					filterList[i].status.selected = false;
				}
				return;
			}

			if (
				(defaultItems && defaultItems.length) ||
				(preFilter && preFilter.length)
			) {
				//Save the state if default items or a prefilter were passed in
				dataStore.saveState(
					stateIdentifier,
					JSON.stringify(savedFilters)
				);
			}

			if (forcePreFilter) {
				savedFilters = preFilter;
			}

			//run filter actions after we know how many we have so we can tag the last one
			var runActions = [];
			for (i = 0; i < savedFilters.length; i++) {
				for (ii = 0; ii < filterList.length; ii++) {
					if (savedFilters[i] === filterList[ii].name) {
						filterList[ii].status.selected = true;
						runActions.push(filterList[ii]);
					}
				}
			}
			for (var a = 0; a < runActions.length; a++) {
				var isLast = false;
				if (runActions.length - a === 1) {
					isLast = true;
				}
				runFilterActions(runActions[a], type, isLast);
			}

			if (isShare) {
				for (i = 0; i < filterList.length; i++) {
					if (
						filterList[i].status.selected &&
						filterList[i].folderID &&
						!filterList[i].isFolder
					) {
						folderHash[filterList[i].folderID] = {
							folderID: filterList[i].folderID,
							isFolder: true,
						};
					}
				}

				for (i = filterList.length - 1; i >= 0; i--) {
					if (
						(!allFilters &&
							!filterList[i].status.selected &&
							!filterList[i].isFolder) ||
						(filterList[i].isFolder &&
							!folderHash[filterList[i].folderID])
					) {
						filterList.splice(i, 1);
					} else {
						filterList[i].status.selected = false;
						// Add any url parameter filters if we are in a share
						if (
							config.isShare &&
							defaultItems &&
							defaultItems.length
						) {
							for (ii = 0; ii < defaultItems.length; ii++) {
								if (filterList[i].name === defaultItems[ii]) {
									filterList[i].status.selected = true;
									break;
								}
							}
						}
					}
				}
			}
		}

		function restoreSessionCollapseState(items, field, preCollapseState) {
			var stateIdentifier = field + 'Collapsed';
			var savedState;
			if (preCollapseState) {
				savedState = preCollapseState;
			} else {
				savedState = JSON.parse(dataStore.getState(stateIdentifier));
			}

			if (!savedState) {
				return;
			}

			for (var i = 0; i < savedState.length; i++) {
				for (var ii = 0; ii < items.length; ii++) {
					if (savedState[i] === items[ii].name) {
						items[ii].status.collapsed = true;
					}
				}
			}
		}

		function restoreSessionFolderExpandedState(
			items,
			field,
			preExpandedState
		) {
			if (field !== 'resource' && field !== 'status') {
				return;
			}
			var stateIdentifier = field + 'FolderExpanded';
			var savedState;
			if (preExpandedState) {
				savedState = preExpandedState;
			} else {
				savedState = JSON.parse(dataStore.getState(stateIdentifier));
			}

			if (!savedState) {
				return;
			}

			for (var i = 0; i < savedState.length; i++) {
				for (var ii = 0; ii < items.length; ii++) {
					if (savedState[i] === items[ii].folderID) {
						items[ii].status.folderExpanded = true;
					}
				}
			}
		}

		function getFilterState() {
			var textFilters = seedcodeCalendar.get('textFilters');
			var config = seedcodeCalendar.get('config');
			var hasSelection;
			var result = {};
			var filterHash = {};

			filterHash['text'] = {
				selected: !!textFilters,
			};

			filterHash['resourceListFilter'] = {
				selected: !!config.resourceListFilter,
			};

			filterHash['statuses'] = getIsFilterEnabled('statuses', true);

			filterHash['resources'] = getIsFilterEnabled('resources', true);

			filterHash['contacts'] = getIsFilterEnabled('contacts');

			filterHash['projects'] = getIsFilterEnabled('projects');

			result.hasSelection =
				filterHash['resourceListFilter'].selected ||
				filterHash['text'].selected ||
				filterHash['statuses'].selected ||
				filterHash['resources'].selected ||
				filterHash['contacts'].selected ||
				filterHash['projects'].selected;

			result.filterHash = filterHash;

			return result;
		}

		function getIsFilterEnabled(filterListIdentifier, checkFolders) {
			var selected;
			var hasSelection;
			var result = {};
			var folderHash = {};
			var partialFolderHash = {};
			var itemList = seedcodeCalendar.get(filterListIdentifier);

			//Exit if we don't get a valid item list back
			if (!itemList) {
				return false;
			}

			for (var i = 0; i < itemList.length; i++) {
				selected =
					itemList[i].status &&
					itemList[i].status.selected &&
					!itemList[i].isFolder;

				if (itemList[i].folderID && !itemList[i].isFolder) {
					if (itemList[i].folderID in folderHash) {
						if (folderHash[itemList[i].folderID] !== selected) {
							partialFolderHash[itemList[i].folderID] = true;
						}
					} else {
						folderHash[itemList[i].folderID] = selected;
					}
				}

				if (selected) {
					hasSelection = true;
					if (!checkFolders)
						return {
							selected: hasSelection,
						};
				}
			}

			if (checkFolders) {
				for (var i = 0; i < itemList.length; i++) {
					if (
						itemList[i].isFolder &&
						partialFolderHash[itemList[i].folderID]
					) {
						if (itemList[i].status.selected) {
							itemList[i].status.selected = false;
							runFilterActions(itemList[i], filterListIdentifier);
						} else {
							itemList[i].status.selected = false;
						}
					} else if (
						itemList[i].isFolder &&
						folderHash[itemList[i].folderID]
					) {
						if (!itemList[i].status.selected) {
							itemList[i].status.selected = true;
							runFilterActions(itemList[i], filterListIdentifier);
						} else {
							itemList[i].status.selected = true;
						}
					}
				}
			}

			return {
				selected: hasSelection,
				partialFolderSelected: partialFolderHash,
			};
		}

		function getEnabledFilterItems(filterListIdentifier, dynamicFilter) {
			var hash = {};
			var itemsFiltered;
			var output = [];
			var selected;
			var itemList = seedcodeCalendar.get(filterListIdentifier);
			var items;

			//Exit if we don't get a valid item list back
			if (!itemList) {
				return [];
			}

			items = itemList.filter(function (value, index, array) {
				if (value.status && value.status.selected) {
					itemsFiltered = true;
				}
				return !value.isFolder;
			});

			for (var i = 0; i < items.length; i++) {
				selected = items[i].status && items[i].status.selected;
				//dynamic filter lets us assume that if no filter is selected that is appropriate whereas standard filters if none are selected we treat it like all are selected. This is because those have none as an option
				if (
					(!itemsFiltered &&
						!dynamicFilter &&
						!hash[items[i].name]) ||
					(selected && !hash[items[i].name])
				) {
					output.push(items[i]);
					//Could wrap this line in an if statement if we wanted to only prevent duplicates in certain instances
					hash[items[i].name] = true;
				}
			}
			return output;
		}

		function applyFilterSearch(
			keyWord,
			type,
			updateView,
			forceApply,
			callback
		) {
			var config = seedcodeCalendar.get('config');
			var saveState;
			var filterType;
			var listItems;
			var filteredItems = [];

			config[type + 'ListFilter'] = keyWord;

			if (!keyWord && !updateView && !forceApply) {
				return;
			}

			if (type === 'resource') {
				filterType = 'resources';
			} else if (type === 'status') {
				filterType = 'statuses';
			}

			listItems = seedcodeCalendar.get(filterType);

			filteredItems = getFilteredItems(keyWord, filterType, listItems);

			dataStore.saveState(type + 'ListFilter', keyWord);

			// resourcesFiltered, statusesFiltered
			// This is for tracking the filtered items in the list. Set to null if nothing is filtered
			config[filterType + 'Filtered'] = keyWord ? filteredItems : null;

			if (updateView) {
				resetSelected(listItems, type);
				updateFilterSavedState(listItems, type);
			}

			if (callback) {
				callback(filteredItems);
			}

			$rootScope.$broadcast(filterType + 'Filtered', filteredItems);

			if (updateView) {
				$rootScope.$broadcast('updateFilters', true);
				manageResources.reset();
			}
		}

		function getFilteredItems(keyWord, filterType, listItems) {
			var filteredItems = [];
			var isShown;
			var fieldMap = {};
			var filterShownMap = {};

			if (!listItems) {
				listItems = seedcodeCalendar.get(filterType);
			}

			fieldMap.name = 'name';
			fieldMap.description = 'description';
			fieldMap.tags = 'tags';
			fieldMap.folderName = 'folderName';

			for (var i = 0; i < listItems.length; i++) {
				isShown = findTextInFields(
					keyWord,
					null,
					listItems[i],
					fieldMap
				);
				if (isShown && listItems[i].isFolder) {
					// Mark contents of folder as shown
					filterShownMap[listItems[i].id] = 'parent';
				} else if (isShown && listItems[i].folderID) {
					// Mark folder as shown
					filterShownMap[listItems[i].folderID] = 'child';
					filterShownMap[listItems[i].id] = 'self';
				} else if (isShown) {
					filterShownMap[listItems[i].id] = 'self';
				}
			}

			for (var i = 0; i < listItems.length; i++) {
				var itemShown = filterShownMap[listItems[i].id];
				var folderShown = filterShownMap[listItems[i].folderID];
				if (
					(folderShown && folderShown === 'parent') ||
					(folderShown &&
						folderShown === 'child' &&
						listItems[i].isFolder) ||
					(itemShown && itemShown === 'self')
				) {
					filteredItems.push(listItems[i]);
				}
			}

			// If no resources are found then lets reset the filtered list to display all
			if (!filteredItems.length) {
				filteredItems = null;
			}

			return filteredItems;
		}

		function createFilterEdit(filterItem) {
			var filterEdit = JSON.parse(JSON.stringify(filterItem)) || {};
			var tags = [];
			var classPrefix = 'dbkcustom-';
			var classPrefixLength = classPrefix.length;

			if (
				filterItem.class &&
				filterItem.class.slice(0, classPrefixLength) === classPrefix
			) {
				filterEdit.class = filterItem.class.slice(classPrefixLength);
			}

			filterEdit.color =
				filterItem.color || utilities.generateRandomColor();

			if (filterItem.tags && filterItem.tags.length) {
				for (var i = 0; i < filterItem.tags.length; i++) {
					tags.push(filterItem.tags[i].name);
				}
				filterEdit.tags = tags.join(', ');
			}
			return filterEdit;
		}

		function filterIsDynamic(filterItem) {
			return (
				filterItem.name &&
				(!filterItem.status || !filterItem.status.stored)
			);
		}

		function filterIsEditable(filterName) {
			var config = seedcodeCalendar.get('config');
			return (
				!filterIsNoFilterLabel(filterName) &&
				!config.isShare &&
				(config.admin || config.manageFilters)
			);
		}

		function filterIsNoFilterLabel(filterName) {
			var config = seedcodeCalendar.get('config');
			return config.noFilterLabel === filterName;
		}
	}
})();
